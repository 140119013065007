import React, { useEffect, useState } from "react";
import Tableform from "../../components/general/Tableform";
import { API } from "../../api";
import { errorToast } from "../../hooks/useToast";
import { mySubscriptionscolumn } from "../../data/mySubscriptionscolumn";
import { myInvoicescolumn } from "../../data/myInvoicescolumn";
import Loader from "../../components/general/Loader";
import SubscriptionModal from "../../components/general/Modal/SubscriptionModal";
import { useSelector } from "react-redux";
import MetaData from "../../components/seo/MetaData";

const MySubscriptionsPage = () => {
  const [itemPerPage, setItemPerPage] = useState(5);
  const [subcription, setSubcription] = useState([]);
  const [invoice, setInvoice] = useState([]);
  const [loading, setLoading] = useState(true);

  const [loading2, setLoading2] = useState(null);
  const [error, setError] = useState(null);
  const authorized = useSelector((state) => state.user.user.authorized);
  const [summery, setSummery] = useState({});

  const getSubscription = async () => {
    try {
      const response = await API.getMySubscription();
      setLoading(false);
      setSubcription(response?.data?.data || []);
    } catch (error) {
      setLoading(false);
      errorToast(error, "Can not fetch data");
    }
  };
  const getinvoice = async () => {
    try {
      const response = await API.getMyInvoice();
      const response1 = await API.getUserSummner();
      setSummery(response1?.data?.data || {});
      setLoading(false);

      setInvoice(response?.data?.data || []);
    } catch (error) {
      setLoading(false);
      errorToast(error, "Can not fetch data");
    }
  };

  useEffect(() => {
    getSubscription();
    getinvoice();
  }, []);

  const handlSubscription = async (id, promo) => {
    setLoading2(true);
    try {
      let payload;

      if (promo?.promoCode) {
        payload = {
          subscriptionId: Number(id),
          promoCode: promo?.promoCode,
        };
      } else {
        payload = {
          subscriptionId: Number(id),
        };
      }
      const response = await API.registerSubscription(payload);
      setLoading2(false);

      window.location.assign(`${response?.data?.data}`);
    } catch (error) {
      errorToast(error, "Can not subscribe at the moment");
      setError(error);
      setLoading2(false);
    }
  };

  return (
    <div className="flex gap-4 flex-col">
      <MetaData
        title={" Subscription Management - Qbank Model"}
        description={
          "Manage your subscription plans with Qbank Model. Update, renew, or change your subscription to fit your needs."
        }
        keywords={
          "subscription management, Qbank Model plans, update subscription, renew subscription"
        }
      />

      {loading ? (
        <Loader />
      ) : (
        <>
       
          <div className="all-plans">
            <h2 className="mb-4 text-2xl font-semibold">
              All Subscription Plans
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {summery?.subscriptions &&
                summery?.subscriptions.map((subscription) => (
                  <div
                    key={subscription.id}
                    className={`item flex justify-between items-center gap-4 border rounded-md shadow-md py-8 px-4 md:px-6 group ${
                      subscription?.id === summery?.userSubscriptionId
                        ? "bg-themeSecondry-0"
                        : ""
                    }`}
                  >
                    <div className="card tile">
                      <div
                        className={`card-header text-xl mb-1 font-bold text-themePrimary-0 ${
                          subscription?.id === summery?.userSubscriptionId
                            ? "text-white"
                            : "text-themePrimary-0"
                        }`}
                      >
                        {subscription?.name}
                      </div>
                      <div className="card-block">
                        <h1
                          className={`card-title text-3xl font-bold text-themeSecondry-0 ${
                            subscription?.id === summery.userSubscriptionId
                              ? "text-white"
                              : "text-themePrimary-0"
                          }`}
                        >
                          <span className="text-2xl mr-1">$</span>
                          {subscription?.price}
                        </h1>
                      </div>
                    </div>
                    <div className="btn-area">
                      {/* <Button
                        className={` !opacity-100   py-3 px-6 rounded-full text-white transition-all divide-success-200 ${
                          subscription.id === summery.userSubscriptionId
                            ? "bg-themeButton-0 hover:bg-white hover:text-black"
                            : "bg-themeButton-0 hover:bg-themeButtonHover-0"
                        }`}
                      >
                        {subscription.id === summery.userSubscriptionId
                          ? "Current Plan"
                          : "Buy Now"}
                      </Button> */}
                      <SubscriptionModal
                        authorized={authorized}
                        loading={loading2}
                        id={subscription?.id}
                        item={subscription}
                        handlSubscription={handlSubscription}
                        error={error?.response?.data?.message}
                        text={
                          subscription.id === summery.userSubscriptionId
                            ? "Current Plan"
                            : "Buy Now"
                        }
                        skip={true}
                      />
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <Tableform
            tableheading="My Subscriptions"
            filterdata={subcription}
            tablecolumns={mySubscriptionscolumn}
            itemPerPage={itemPerPage}
            noContentText={"No subscription yet"}
          />
          <Tableform
            tableheading="My Invoices"
            filterdata={invoice}
            tablecolumns={myInvoicescolumn}
            itemPerPage={itemPerPage}
            invoicePreview={true}
            noContentText={"No purchase history"}
            // preview={true}
          />
        </>
      )}
    </div>
  );
};

export default MySubscriptionsPage;
