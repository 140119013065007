import axios from "axios";
import Cookies from "js-cookie";


const BASE_URL = process.env.REACT_APP_BASE_URL_SERVER;

const API = axios.create({
  baseURL: BASE_URL,
});

API.interceptors.request.use(
  function (config)
  {
    let token;
    if (Cookies.get("token"))
    {
      token = Cookies.get("token");
    }
    if (token)
    {
      config.headers.authorization = `${token}`;
    } else
    {
      // Handle the case when the token is missing (expired or not set)
    }
    return config;
  },
  function (error)
  {
    return Promise.reject(error);
  }
);

// Response interceptor to handle 401 errors
API.interceptors.response.use(
  (response) =>
  {
    return response; // If the response is successful, just return it
  },
  (error) =>
  {
    if (error.response && error.response.status === 401)
    {
      // Handle 401 error, e.g., redirect to login page
      console.log("Unauthorized access, redirecting to login...");
      const currentPath = window.location.pathname;
      if (
        currentPath !== "/sign-in" &&
        currentPath !== "/sign-up" &&
        currentPath !== "subscriptions"
      )
      {
        // Redirect to sign-in if the current path is not sign-in or sign-up
        Cookies.remove("token");
        localStorage.clear();
        window.location.assign("/sign-in");
      }
      Cookies.remove("token");
      localStorage.clear();
    }
    return Promise.reject(error); // Reject the promise with the error
  }
);


// API.interceptors.response.use(
//   function (response) {
//     // Return the response if it is successful
//     return response;
//   },
//   function (error) {
//     // Check if the error response is unauthorized (status code 401)
//     if (error.response && error.response.status === 401) {
//       // Check the current URL path
//       const currentPath = window.location.pathname;
//       if (
//         currentPath !== "/sign-in" &&
//         currentPath !== "/sign-up" &&
//         currentPath !== "subscriptions"
//       ) {
//         // Redirect to sign-in if the current path is not sign-in or sign-up
//         window.location.assign("/sign-in");
//         Cookies.remove("token");
//         localStorage.clear();
//       }
//     }
//     return Promise.reject(error);
//   }
// );

// exam
API.getAllExams = () =>
{
  return API.get("exam");
};

API.getSingleExam = (id) =>
{
  return API.get(`exam/${id}`);
};

API.updateUser = (data) =>
{
  return API.patch("/user", data);
};

API.createUserExam = (data) =>
{
  return API.post("/user/exam", data);
};
API.pauseUserExam = (data) =>
{
  return API.post("/user/exam/pause", data);
};

API.getSingleUserExam = (id) =>
{
  return API.get(`/user/exam/${id}`);
};
API.deleteUserExam = (id) =>
{
  return API.delete(`/user/exam/${id}`);
};

// answer

API.submitUserAnswer = (data) =>
{
  return API.post("/user/answers", data);
};

API.generateResult = (data) =>
{
  return API.post("/user/exam/result", data);
};
API.getUserExamResult = (id) =>
{
  return API.get(`/user/exam/result/${id}`);
};

API.getPreviouslySubmittedAnswers = (userExamId) =>
{
  return API.get(`/user/answers?userExamId=${userExamId}`);
};

//Category

API.getAllCategories = () =>
{
  return API.get("category");
};

API.getSingleCategorey = (id, data) =>
{
  return API.patch(`category/${id}`, data);
};

API.getCateogriesTopics = () =>
{
  return API.get("category/topic");
};

// blogs

API.getAllBlogs = () =>
{
  return API.get("blog");
};

API.getSingleBlogBySlug = (slug) =>
{
  return API.get(`blog/data/${slug}`);
};

//user

API.changePassword = (data) =>
{
  return API.patch("/user/change-password", data);
};

API.getSingleUsers = (id) =>
{
  return API.get(`user/${id}`);
};

API.tempPassword = (data) =>
{
  return API.post(`user/temp-password`, data);
};

API.registerUser = (data) =>
{
  return API.post("user", data);
};
API.registerUserTemp = (data) =>
{
  return API.post("/user/temp-register", data);
};
API.registerUserTempOTP = () =>
{
  return API.get("/user/temp-otp");
};
API.verifyUser = (data) =>
{
  return API.post("user/verify-otp", data);
};
API.updateUser = (data) =>
{
  return API.patch("user", data);
};
API.resendOtp = () =>
{
  return API.post("user/resend-otp");
};
API.forgetPassword = (data) =>
{
  return API.post("user/forget-password", data);
};
API.forgetPasswordOtp = (data) =>
{
  return API.post("user/forget-pass-otp", data);
};
API.forgetPasswordVerifyOtp = (data) =>
{
  return API.post("user/forget-pass-verify-otp", data);
};

API.resetPassword = (data) =>
{
  return API.post("user/reset-password", data);
};

API.logInUser = (data) =>
{
  return API.post("user/login", data);
};

API.SignUp = (data) =>
{
  return API.post("user/reset-password", data);
};

API.getMySubscription = () =>
{
  return API.get("user/my-subscription");
};
API.getMySubscriptionCurrent = () =>
{
  return API.get("user/my-current-subscription");
};
API.attemptsLeft = () =>
{
  return API.get("user/my-attempts");
};
API.userProgressStatistics = () =>
{
  return API.get("user/my-progress");
};

API.getMyInvoice = () =>
{
  return API.get("user/my-invoice");
};

API.userExam = (mode) =>
{
  return API.get(`user/exam?${mode}`);
};

API.getMyInvoice = () =>
{
  return API.get("user/my-invoice");
};

API.getUserBillInvoice = (id) =>
{
  return API.get(`user/bill?userSubscriptionId=${id}`);
};

API.getCorrectWrong = () =>
{
  return API.get("/user/exam/result/last-result");
};
API.getUserDashboardStatistics = () =>
{
  return API.get("/user/exam/result/statistics");
};

API.getUserSummner = () =>
{
  return API.get("user/summary/my-subscription");
};

//subscription
API.getAllSubscription = (data) =>
{
  return API.get("subscription", data);
};

// promo

API.getAllPromo = () =>
{
  return API.get("promo");
};

API.getSingleSubscription = (id) =>
{
  return API.get(`subscription/${id}`);
};

API.getPublishKey = () =>
{
  return API.get("/user/subscription/config");
};
API.paymentIntend = (data) =>
{
  return API.post("/user/subscription/create-payment-intend", data);
};

API.registerSubscription = (data) =>
{
  return API.post("/user/subscription/self", data);
};
API.getTotal = (data) =>
{
  return API.post("/user/subscription/amount", data);
};
API.getTotalGeneral = (data) =>
{
  return API.post("/user/subscription/amount/general", data);
};

//topics
API.getAllTopics = (data) =>
{
  return API.get("topic", data);
};

//questions
API.getAllQuestion = (data) =>
{
  return API.get("question", data);
};
API.getSingleQuestion = (id) =>
{
  return API.get(`question/${id}`);
};

API.uploadImage = (data) =>
{
  return API.post("/upload", data);
};

//feedback
API.registerFeedBack = (data) =>
{
  return API.post("/feedback", data);
};

//newsletter
API.registerNewsletter = (data) =>
{
  return API.post("/newsletter", data);
};

// report

API.registerReport = (data) =>
{
  return API.post("/report", data);
};
//statistics

API.getStatistics = (startDate, endDate, choice) =>
{
  return API.get(
    `/dashboard?startDate=${startDate}&endDate=${endDate}&choice=${choice}`
  );
};

//contact

API.sendContactQuery = (data) =>
{
  return API.post("/contact", data);
};
export { API };
