import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./style/global.css";
import { NextUIProvider } from "@nextui-org/react";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import { store } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import {persistStore} from "redux-persist"
import { useDispatch } from "react-redux";
import { logoutUser } from "./store/slices/userSlice";
import Cookies from "js-cookie";
const root = ReactDOM.createRoot(document.getElementById("root"));

let persistor = persistStore(store)

const AppWrapper = () => {
  const dispatch = useDispatch();

  // Check if the token is missing from the localStorage or cookie on app load
  useEffect(() => {
    const token = Cookies.get("token")
    if (!token) {
      // Dispatch logout action if token is missing
      dispatch(logoutUser());
    }
  }, []);

  return (
    <NextUIProvider>
      <ToastContainer />
      <RouterProvider router={router} />
    </NextUIProvider>
  );
};

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
      <AppWrapper />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
