import { Button } from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import SubscriptionModal from "../general/Modal/SubscriptionModal";
import { redCheck } from "../../assets";
import { API } from "../../api";
import { errorToast } from "../../hooks/useToast";

const PricingCart = ({
  plan,
  id,
  duration,
  durationMode,
  price,
  loading,
  authorized,
  item,
  recommended,
  error,
  activeSubsription,
  discounted,
  skipModel,
}) => {
  const keyPoints = [
    { name: "Unlimited Access" },

    { name: "Explanation Exam Mode" },
    { name: "Mock Exam Mode" },
    { name: "Categories based Questions" },
    { name: "Key Points with Illustrations" },
    { name: "Multiple Difficulty Levels" },
    { name: "24/7 Dedicated Support" },
  ];

  const [filterMode, setFilterMode] = useState(durationMode);
  const [loading2, setLoading2] = useState(null);
  const [subscriptionError, setSubscriptionError] = useState(null);

  useEffect(() => {
    if (duration === 1) {
      setFilterMode("month");
    }
  }, [filterMode]);


  const handlSubscription = async (id, promo) => {
    setLoading2(true);
    try {
      let payload;

      if (promo?.promoCode) {
        payload = {
          subscriptionId: Number(id),
          promoCode: promo?.promoCode,
        };
      } else {
        payload = {
          subscriptionId: Number(id),
        };
      }
      const response = await API.registerSubscription(payload);
      setLoading2(false);

      window.location.assign(`${response?.data?.data}`);
    } catch (error) {
      errorToast(error, "Can not subscribe at the moment");
      setSubscriptionError(error);
      setLoading2(false);
    }
  };

  return (
    <>
      <div
        className={` bg-white  relative min-w-[300px] md:min-w-[360px]  price-cart group w-full  mx-auto text-center   flex flex-col gap-1       rounded-[24px] shadow-md hover:shadow-transparent transition-all     `}
      >
        {recommended && (
          <div
            className="absolute -top-10 left-1/2 transform -translate-x-1/2 text-white font-bold bg-themeSecondry-0 
          max-w-[250px] md:max-w-[300px] py-2 px-4 w-full text-center rounded-t-[24px] z-10"
          >
            Most Popular
          </div>
        )}

        <div
          className={`${
            recommended ? "bg-themeButton-0" : "bg-themeSecondry-0"
          } flex flex-col justify-center rounded-t-[24px] py-3 items-center   `}
        >
          <h3
            className={`text-xl capitalize md:text-xl text-start ${
              recommended ? "text-white " : "text-white"
            }   `}
          >
            {plan}
          </h3>

          <p
            className={` ${
              recommended ? "  text-white  " : "text-white"
            } capitalize text-start  text-2xl font-bold`}
          >
            {duration} {filterMode}{" "}
          </p>
        </div>

        <div className="flex flex-col px-10">
          <div className="flex justify-start items-center gap-3 my-3">
            <span className="text-3xl text-[#C4C4C4] font-medium line-through">
              ${discounted?.amount}
            </span>
            <div className="px-3 py-1 border-2 border-themeButton-0 rounded-[28px]">
              <p className="text-lg font-medium text-themeButton-0">20% OFF</p>
            </div>
          </div>

          <p className="text-4xl text-themeSecondry-0  font-bold  text-start ">
            ${price}
          </p>

          <p
            className={`text-themeSecondryDark-0 font-bold text-start text-sm py-3 `}
          >
            What’s included
          </p>

          {keyPoints?.map((item, index) => (
            <div
              key={index}
              className="flex justify-start items-center gap-2 py-1"
            >
              <img src={redCheck} alt="redCircle" className="w-5" />
              <p className={` text-themeSecondry-0 `}>{item?.name}</p>
            </div>
          ))}

          <div className="mt-6 mb-8">
            <SubscriptionModal
              authorized={authorized}
              loading={loading || loading2}
              handlSubscription={handlSubscription}
              id={id}
              activeSubsription={activeSubsription}
              item={item}
              error={error || subscriptionError?.response?.data?.message}
              text={"Buy"}
              skip={skipModel}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PricingCart;
