import { FaFacebookF, FaInstagram } from "react-icons/fa";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import
  {
    americanexp,
    card1,
    card2,
    card3,
    discover,
    logoFooter,
  } from "../../assets";
import { RiInstagramFill } from "react-icons/ri";
import { useForm } from "react-hook-form";
import { API } from "../../api";
import { errorToast, successToast } from "../../hooks/useToast";

const Footer = () =>
{
  const [loading, setLoading] = useState(false);
  const [Error, setError] = useState("");
  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() =>
  {
    const timeoutId = setTimeout(() =>
    {
      setError("");
    }, 3000);
    return () => clearTimeout(timeoutId);
  }, [Error]);

  const onSubmit = async (data) =>
  {
    setError("");
    setLoading(true);
    try
    {
      await API.registerNewsletter(data);
      successToast(
        "We have received your query! Someone will get in touch with you soon."
      );
      reset();
      setValue("email", "");
      setLoading(false);
    } catch (error)
    {
      setLoading(false);
      errorToast(error, "Can not submit your query! Try again later");
      console.log(error);
      if (error?.response?.status === 400)
      {
        setError(error?.response?.data?.message);
      }
    }
  };
  return (
    <div className="text-white bg-themeSecondryDark-0 ">
      <div className="  mx-auto">
        <div className="container">
          <div className=" newsletter mx-auto max-w-[460px] text-center  py-10 lg:py-16">
            <h2 className="capitalize text-white mb-3">
              Subscribe for Newsletter
            </h2>
            <p className="mb-3  font-[300] text-base ">
              Get the latest news from us
            </p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="grid grid-cols-1 md:grid-cols-1 gap-2 items-start justify-center">
                <div className="field-wrapper col-span-2 flex flex-col ">
                  <div className="w-full">
                    <input
                      type="email"
                      className=" rounded-full py-3 px-6 focus:border-none focus:outline-none w-full text-black"
                      placeholder="Your Email Address"
                      {...register("email", {
                        required: "Please enter an email",
                        pattern: {
                          value: /^\S+@\S+$/i,
                          message: "Please enter a valid email address",
                        },
                      })}
                    />
                    {Error && Error?.map((err, index) => (
                      <p key={index} className="error text-red-500 text-sm mt-1">
                        {err}
                      </p>
                    ))}
                    {errors.Email && (
                      <span className="error text-red-500 text-sm mt-1">
                        {errors.Email.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="button-wrapper GeneralSans flex justify-center mt-[9px]">
                  <input
                    type="submit"
                    className="bg-themeButton-0  rounded-full font-bold interFont text-white max-w-[170px] w-full py-3 transition-all duration-200 cursor-pointer hover:bg-themeButtonHover-0"
                    value="Subscribe"
                    disabled={loading}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        <footer className=" text-start lg:text-left border-t-1 border-white">
          <div className="container">
            <div className="py-10  lg:pt-16 lg:pb-10  text-left  dark:border-neutral-500  ">
              <div className="grid grid-cols-1 md:grid-cols-6 gap-5">
                <div className="md:col-span-3">
                  <div className="w-full lg:max-w-[400px]  ">
                    <Link to="/" className="cursor-pointer">
                      <img
                        src={logoFooter}
                        alt="Header_logo"
                        width={500}
                        height={500}
                        className=" max-w-[300px]  mb-4  "
                      />
                    </Link>
                    <p className="text-sm ">
                      Discover your pathway to Australian medical excellence
                      with Q bank Model. Our comprehensive AMC exam preparation
                      portal is designed to help you succeed in your medical
                      career. Trust us to guide you every step of the way.
                    </p>
                    <div className="social-list my-4">
                      <ul className="flex flex-row  items-center gap-4">
                        <li>Follow us </li>
                        <li>
                          <Link
                            to="https://www.facebook.com/AMCQbankmodel"
                            className="group p-2 rounded-full bg-themeButton-0 hover:bg-white table"
                          >
                            <FaFacebookF className="group-hover:text-themeButton-0" />
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="https://www.instagram.com/qbankmodel/"
                            className="group p-2 rounded-full bg-themeButton-0 hover:bg-white table"
                          >
                            <FaInstagram className="group-hover:text-themeButton-0" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="link-list">
                  <h2 className="mb-4 text-base text-white">Quick Links</h2>
                  <ul className="flex flex-col justify-start text-left gap-1">
                    <li>
                      <Link to="/user/exam" className="text-sm font-normal">
                        Free Test Drive
                      </Link>{" "}
                    </li>
                    <li>
                      <Link to="/subscriptions" className="text-sm font-normal">
                        Subscriptions
                      </Link>{" "}
                    </li>

                    <li>
                      <Link to="/sign-in" className="text-sm font-normal">
                        Access Your Account
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact-us" className="text-sm font-normal">
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="link-list">
                  <h2 className="mb-4 text-base text-white">Useful Links</h2>
                  <ul className="flex flex-col justify-start text-left gap-1">
                    <li>
                      <Link to="/about" className="text-sm font-normal">
                        About Us
                      </Link>{" "}
                    </li>
                    <li>
                      <Link to="/blogs" className="text-sm font-normal">
                        Blogs
                      </Link>{" "}
                    </li>


                    <li>
                      <Link to="/sign-up" className="text-sm font-normal">
                        Create Your Account
                      </Link>{" "}
                    </li>
                  </ul>
                </div>
                <div className="link-list">
                  <h2 className="mb-4 text-base text-white">Information</h2>
                  <ul className="flex flex-col justify-start text-left gap-1">
                    <li>
                      <Link
                        to="/terms-conditions"
                        className="text-sm font-normal"
                      >
                        Terms & Conditions
                      </Link>{" "}
                    </li>
                    <li>
                      <Link
                        to="/privacy-policy"
                        className="text-sm font-normal"
                      >
                        Privacy Policy
                      </Link>{" "}
                    </li>
                    <li>
                      <Link
                        to="/faqs"
                        className="text-sm font-normal"
                      >
                        FAQs
                      </Link>{" "}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="copyright mt-4">
                <div className=" flex flex-wrap lg:flex-nowrap w-full gap-1 mb-4">
                  <img
                    src={card1}
                    alt="card1"
                    width={150}
                    height={150}
                    className="max-w-[200px] w-full"
                  />
                  <img
                    src={card2}
                    alt="card1"
                    width={100}
                    height={150}
                    className="max-w-[80px] w-full object-center"
                  />
                  <img
                    src={card3}
                    alt="card1"
                    width={100}
                    height={150}
                    className="max-w-[80px] w-full object-center"
                  />
                  <img
                    src={americanexp}
                    alt="card1"
                    width={100}
                    height={150}
                    className="max-w-[80px] w-full object-center"
                  />
                  <img
                    src={discover}
                    alt="card1"
                    width={100}
                    height={150}
                    className="max-w-[80px] w-full object-center"
                  />
                </div>
                <p className="text-thin text-sm">
                  © 2024 Q Bank Model. All Rights Reserved.
                </p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Footer;
