import React from "react";
import {
  Modal,
  ModalContent,
  ModalBody,
  useDisclosure,
} from "@nextui-org/react";
import { PlayIcon } from "lucide-react";
import { MdClose } from "react-icons/md";

const VideoModal = ({ videoUrl }) => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  return (
    <>
      <button
        className="bg-themeButton-0 text-white px-4 py-2 rounded-md absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]"
        onClick={onOpen}
      >
        <PlayIcon />
      </button>
      <Modal size={"4xl"} isOpen={isOpen} placement={"center"} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <ModalBody className="p-0 relative">
              <div className="relative w-full h-0 pb-[56.25%]">
                {/* <iframe
                  src={`${videoUrl}?autoplay=1&controls=0&showinfo=0&modestbranding=1&rel=0`}
                  className="absolute top-0 left-0 w-full h-full"
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  allowFullScreen
                  title="Video"
                ></iframe> */}
                <iframe
                  src={`https://www.youtube.com/embed/${
                    videoUrl.split("v=")[1].split("&")[0]
                  }?autoplay=1&controls=1&modestbranding=1&rel=0`}
                  className="absolute top-0 left-0 w-full h-full"
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  allowFullScreen
                  title="Video"
                />
              </div>
              <button
                className=" bg-red-500 text-white p-0 rounded-full h-[28px] w-[28px] absolute top-[2%] right-[2%] flex items-center justify-center"
                onClick={onClose}
              >
                <MdClose />
              </button>
            </ModalBody>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default VideoModal;
