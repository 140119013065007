import { createBrowserRouter } from "react-router-dom";
import HomePage from "../pages/home/HomePage";
import RootLayout from "../components/layout/RootLayout";
import SignUpPage from "../pages/sign-up/SignUpPage";
import LoginpPage from "../pages/login/LoginpPage";
import VerifyOtp from "../components/user/verify-otp/VerifyOtp";
import ForgotPasswordPage from "../pages/forgot-password/ForgotPasswordPage";
import ResetPasswordPage from "../pages/reset-password/ResetPasswordPage";
import Pricing from "../pages/pricing/Pricing";
import BlogPage from "../pages/blog/BlogPage";
import BlogSlugPage from "../pages/blog/BlogSlugPage";
import DashboardPage from "../pages/account/DashboardPage";
import MyExamsPage from "../pages/account/MyExamsPage";
import MyProfilePage from "../pages/account/MyProfilePage";
import MySubscriptionsPage from "../pages/account/MySubscriptionsPage";
import MySummaryPage from "../pages/account/MySummaryPage";
import UserDashboard from "../components/layout/userDashboard/UserDashboard";
import CreateExamForm from "../pages/exam/CreateExamForm";
import AttemptExam from "../pages/exam/AttemptExamPage";
import ResultPage from "../pages/exam/ResultPage";
import ProtectedRoutes from "../components/general/ProtectedRoutes";
import AboutPage from "../pages/about/AboutPage";
import ContactPage from "../pages/contact/ContactPage";
import ThankYou from "../pages/pricing/ThankYou";
import UnsuccessfulPage from "../pages/pricing/Unsuccessful";
import ChangePassword from "../pages/account/ChangePassword";
import Policy from "../pages/privacy-policy/Policy";
import Terms from "../pages/terms-conditions";
import SetupProfile from "../pages/setup-profile/SetupProfile";
import DashboardSubscriptions from "../pages/account/DashboardSubscriptions";
import Checkout from "../pages/pricing/Checkout";
import FaqPage from "../pages/faq/FaqPage";
import ErrorPage from "../pages/ErrorPage";

const router = createBrowserRouter([
  {
    element: <RootLayout />,
    errorElement: <ErrorPage />,

    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/sign-up",
        element: <SignUpPage />,
      },
      {
        path: "/sign-in",
        element: <LoginpPage />,
      },

      {
        path: "user",
        children: [
          {
            path: "setup-profile",
            element: <SetupProfile />,
          },
          {
            path: "verify-otp",
            element: <VerifyOtp />,
          },
          {
            path: "forget-password",
            element: <ForgotPasswordPage />,
          },
          {
            path: "reset-password",
            element: <ResetPasswordPage />,
          },
          {
            path: "payment/thankyou",
            element: <ThankYou />,
          },
          {
            path: "payment/Unsuccessful",
            element: <UnsuccessfulPage />,
          },

          {
            path: "exam",
            element: (
              <ProtectedRoutes signUp={true}>
                <CreateExamForm />
              </ProtectedRoutes>
            ),
          },
          {
            path: "result",
            element: (
              <ProtectedRoutes>
                <ResultPage />
              </ProtectedRoutes>
            ),
          },
          {
            path: "attempt/exam",
            element: (
              <ProtectedRoutes>
                <AttemptExam />
              </ProtectedRoutes>
            ),
          },
          {
            path: "dashboard",
            element: (
              <ProtectedRoutes>
                <UserDashboard />
              </ProtectedRoutes>
            ),
            children: [
              {
                path: "",
                element: <DashboardPage />,
              },
              {
                path: "exam",
                element: <MyExamsPage />,
              },
              {
                path: "change-password",
                element: <ChangePassword />,
              },

              {
                path: "profile",
                element: <MyProfilePage />,
              },
              {
                path: "all-subscriptions",
                element: <DashboardSubscriptions />,
              },
              {
                path: "subscription",
                element: <MySubscriptionsPage />,
              },
              {
                path: "summary",
                element: <MySummaryPage />,
              },
            ],
          },
        ],
      },

      {
        path: "/privacy-policy",
        element: <Policy />,
      },
      {
        path: "/terms-conditions",
        element: <Terms />,
      },
      {
        path: "/subscriptions",
        element: <Pricing />,
      },
      {
        path: "/checkout",
        element: <Checkout />,
      },
      {
        path: "/blogs",
        element: <BlogPage />,
      },
      {
        path: "/about",
        element: <AboutPage />,
      },
      {
        path: "/contact-us",
        element: <ContactPage />,
      },
      {
        path: "/blogs/:slug",
        element: <BlogSlugPage />,
      },
      {
        path: "/faqs",
        element: <FaqPage />,
      },
    ],
  },
]);

export { router };
