import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import { FaStar } from "react-icons/fa";
import Chip from "./Chip";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";
import { storiesdata } from "../../data/homepage";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import VideoModal from "./Modal/VideoModal";

const VideoCrouserSlider = () => {
  return (
    <div className="xl:py-14 bg-[#E9F6FF] py-10 lg:py-16">
      <div className="container w-full mx-auto">
        <div className="lg:px-0 lg:py-0 flex flex-col gap-5">
          <div className="items-start">
            <div className="heading-area flex flex-col gap-4 items-center">
              <Chip text={"Video Reviews"} />
              <h2 className="text-center">Success Stories of Doctors</h2>
              <p className="text-center w-full px-3 md:max-w-[1000px]">
                Watch success stories from doctors who used the Q Bank Model to
                prepare for the AMC CAT. These video testimonials highlight how
                the tool helped them boost their exam performance and achieve
                success in their AMC exams.
              </p>
            </div>
          </div>
        </div>
        <div className="w-full mx-auto mt-4 md:mt-8 relative">
          <Swiper
            slidesPerView={1}
            spaceBetween={20}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            navigation={{
              prevEl: ".arrow-left arrow",
              nextEl: ".arrow-right arrow",
            }}
            modules={[Autoplay, Navigation]}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
            className="mySwiper"
          >
            {storiesdata?.map((item, index) => (
              <SwiperSlide key={index}>
                <div
                  className="item p-0 overflow-hidden bg-themePrimary-0 group w-full rounded-[24px] h-auto border border-themeButton-0    object-cover bg-right  bg-[140%,100%] xl:bg-[100%,100%]"
                  // style={{
                  //   backgroundImage: `url(${item.thumbnail})`,
                  //   backgroundRepeat: "no-repeat",

                  // }}
                >
                  <img
                    width={1000}
                    height={1000}
                    src={item.thumbnail}
                    alt="thumbnail"
                    className="w-full rounded-[8px] object-fit"
                  />
                  <div className="flex justify-between items-center w-full h-full">
                    <VideoModal videoUrl={item.url} />
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="arrow-left arrow absolute left-0 top-1/2 transform -translate-y-1/2 z-10 cursor-pointer">
            <AiFillLeftCircle className="text-themeButton-0 text-3xl md:hidden block" />
          </div>
          <div className="arrow-right arrow absolute right-0 top-1/2 transform -translate-y-1/2 z-10 cursor-pointer">
            <AiFillRightCircle className="text-themeButton-0 text-3xl md:hidden block" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoCrouserSlider;
